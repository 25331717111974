<template>
  <div style="width: 150px; padding-bottom: 10px;">
    <p style="margin-bottom: 0px; font-size: 14px;">Tablet Battery Level</p>
    <div class="battery" style="margin-left: auto; margin-right: auto;" :title="(level || 'N/A') + '%'">
      <div class="battery-level" :class="{'battery-warn': level <= 25 && level > 10, 'battery-alert': level <= 10}" :style="`height:${level}%;`">
        <!-- {{level}}% -->
      </div>
      <div class="battery-level-status">
        {{level}}%
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Battery',
    props: {
      level: {
        type: Number,
        default: 100
      },
      last_updated: {
        type: String,
        default: 'N/A'
      }
    },
  }
</script>

<style scoped>

.battery {
  border: 3px solid #333;
  width: 36px;
  height: 56px;
  padding: 2px;
  border-radius: 4px;
  position: relative;
  transform: rotate(-90deg);
  
  &:before {
    content: '';
    height: 3px;
    width: 18px;
    background: white;
    display: block;
    position: absolute;
    top: -6px;
    border-radius: 4px 4px 0 0;
    left: 6px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1px solid #fff;
    border-radius: 2px;
  }
}

.battery-level {
  background: #30b455;  
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  &.battery-warn {
    background-color: #EFAF13;
  }
    
  &.battery-alert {
    background-color: #e81309;
  }    
}

.battery-level-status {
  writing-mode: vertical-lr;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  padding-bottom: 5px;
}
</style>