<template>
    <div class="app-sidebar">
        <!-- Navbar logo -->
        <div class="logo" style="padding-bottom: 25px;">
            <a href="javascript:void(0);" @click="$router.push('/')" class="logo-icon"></a>
        </div>
        <div class="app-menu" style="padding-top: 0px;">
            <ul class="accordion-menu">

                <div v-if="battery.show" class="form-group text-center" style="width: 100%; padding-bottom: 10px;">
                    <Battery :level="battery.level" :last_updated="battery.updated_at" style="margin-left: auto; margin-right: auto;"></Battery>
                </div>

                <div class="form-group" style="padding: 0px 30px;">
                    <!-- TODO - add clientChanged method -->
                    <Multiselect v-model="selectedClient" :class="{ 'multi-dark': $store.state.auth.status.dark }"
                        mode="single" placeholder="Select a client" :close-on-select="false" :searchable="true"
                        :create-option="false" :multiple="false" :options="clients" :allow-empty="false" label="name"
                        track-by="id" name="client" @select="selectClient" id="sidebar-dropdown" />
                </div>

                <!-- Favourites -->
                <ul v-show="false" class="accordion-menu" id="fav-list">
                    <li class="sidebar-title">
                        {{ $t('navigation.side.favourite') }}
                        <i class="material-icons-two-tone add-to-favourite" style="float: right;" @click="showFavourites">
                            add </i>
                    </li>

                    <li v-if="favourites.length === 0" class="no-fav">
                        <!-- <i class="material-icons no-fav-icon"> favorite </i> -->
                        {{ $t('navigation.side.no_fav') }}
                    </li>
                    <div v-else>

                    </div>
                </ul>

                <!-- Navar title -->
                <li class="sidebar-title">
                    {{ $t('navigation.side.navigation') }}
                </li>
                <router-link custom to="/" v-slot="{ href, navigate, isExactActive }">
                    <li :class="[isExactActive && 'active-page']" id="dashboard">
                        <a :href="href" @click="navigate" :class="[isExactActive && 'active']"><i
                                class="material-icons-two-tone">dashboard</i>{{ $t('navigation.side.dashboard') }}
                            <i class="material-icons has-sub-menu favourite" @click="addToFavourite('dashboard')"> favorite
                            </i>
                        </a>
                    </li>
                </router-link>

                <li id="claims" v-if="$can('forms-view') && selected_client !== -1">
                    <a href=""><i class="material-icons-two-tone">flag</i>{{ $t('navigation.side.claims') }}<i
                            class="material-icons has-sub-menu">keyboard_arrow_right</i> <i
                            class="material-icons has-sub-menu favourite" @click="addToFavourite('claims')"> favorite
                        </i></a>
                    <ul class="sub-menu">
                        <!-- <router-link custom to="/claims" v-slot="{href, navigate, isExactActive}">
                                <li :class="[isExactActive && 'active-page']">
                                    <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{$t('navigation.side.process')}}</a>
                                </li>
                            </router-link> -->
                        <!-- New Claim -->
                        <router-link custom to="/newclaim"
                            v-slot="{ href, navigate, isExactActive }">
                            <li :class="[isExactActive && 'active-page']">
                                <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{
                                    $t('navigation.side.new_claim') }}</a>
                            </li>
                        </router-link>

                        <!--  Manage Claims -->
                        <router-link custom to="/evidence"
                            v-slot="{ href, navigate, isExactActive }">
                            <li :class="[isExactActive && 'active-page']">
                                <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{
                                    $t('navigation.side.manage_claims') }}</a>
                            </li>
                        </router-link>
                    </ul>
                </li>

                <!-- <router-link custom to="/events" v-slot="{href, navigate, isExactActive}">
                        <li :class="[isExactActive && 'active-page']">
                            <a :href="href" @click="navigate" :class="[isExactActive && 'active']"><i class="material-icons-two-tone">directions_car</i>{{$t('navigation.side.events')}}</a>
                        </li>
                    </router-link> -->

                <!-- Events new -->
                <router-link v-if="$can('events-view') && selected_client !== -1" custom to="/eventsnew"
                    v-slot="{ href, navigate, isExactActive }">
                    <li :class="[isExactActive && 'active-page']" id="eventsnew">
                        <a :href="href" @click="navigate" :class="[isExactActive && 'active']"><i
                                class="material-icons-two-tone">directions_car</i>{{ $t('navigation.side.events') }}
                            <i class="material-icons has-sub-menu favourite" @click="addToFavourite('eventsnew')"> favorite
                            </i>
                        </a>

                    </li>
                </router-link>

                <!-- Blacklist -->
                <li v-if="$can('blacklist-view') && selected_client !== -1">
                    <a href=""><i class="material-icons-two-tone">gpp_bad</i>{{ $t('navigation.side.blacklist') }}<i
                            class="material-icons has-sub-menu">keyboard_arrow_right</i> <i
                            class="material-icons has-sub-menu favourite" @click="addToFavourite('blacklist')"> favorite
                        </i> </a>
                    <ul class="sub-menu">

                        <!-- View Blacklist -->
                        <router-link custom to="/blacklist" v-slot="{ href, navigate, isExactActive }">
                            <li :class="[isExactActive && 'active-page']">
                                <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{
                                    $t('navigation.side.view_blacklist') }}</a>
                            </li>
                        </router-link>

                        <!-- Add New Vehicle -->
                        <router-link custom to="/blacklist/new" v-slot="{ href, navigate, isExactActive }">
                            <li :class="[isExactActive && 'active-page']">
                                <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{
                                    $t('navigation.side.add_new_blacklist') }}</a>
                            </li>
                        </router-link>
                    </ul>
                </li>

                <!-- Customer Service Message -->
                <router-link v-if="$can('vehicles-view') && selected_client !== -1" custom to="/customerservice"
                    v-slot="{ href, navigate, isExactActive }">
                    <li :class="[isExactActive && 'active-page']" id="customerservice">
                        <a :href="href" @click="navigate" :class="[isExactActive && 'active']"><i
                                class="material-icons-two-tone">comment</i>Customer Service
                        </a>

                    </li>
                </router-link>

                <!-- <router-link custom to="/sites" v-slot="{href, navigate, isExactActive}">
                        <li :class="[isExactActive && 'active-page']">
                            <a :href="href" @click="navigate" :class="[isExactActive && 'active']"><i class="material-icons-two-tone">place</i>Sites</a>
                        </li>
                    </router-link> -->
                <li v-if="$can('sites-map') || $can('sites-view')">
                    <a href=""><i class="material-icons-two-tone">place</i>{{ $t('navigation.side.sites') }}<i
                            class="material-icons has-sub-menu">keyboard_arrow_right</i> <i
                            class="material-icons has-sub-menu favourite" @click="addToFavourite('sites')"> favorite </i>
                    </a>
                    <ul class="sub-menu">
                        <router-link v-if="$can('sites-view') && selectedClient !== -1" custom to="/sites"
                            v-slot="{ href, navigate, isExactActive }">
                            <li :class="[isExactActive && 'active-page']">
                                <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{
                                    $t('navigation.side.view_sites') }}</a>
                            </li>
                        </router-link>
                        <router-link v-if="$can('sites-map') && selectedClient !== -1" custom to="/map"
                            v-slot="{ href, navigate, isExactActive }">
                            <li :class="[isExactActive && 'active-page']">
                                <a :href="href" @click="navigate" :class="[isExactActive && 'active']">Map</a>
                            </li>
                        </router-link>
                        <!-- <router-link v-if="$can('sites-edit') && selectedClient !== -1"  custom to="/sites/new" v-slot="{href, navigate, isExactActive}">
                                <li :class="[isExactActive && 'active-page']">
                                    <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{$t('navigation.side.new_site')}}</a>
                                </li>
                            </router-link> -->
                        <!-- <router-link custom to="/sites/groups" v-slot="{href, navigate, isExactActive}">
                                <li :class="[isExactActive && 'active-page']">
                                    <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{$t('navigation.side.groups')}}</a>
                                </li>
                            </router-link>
                            <router-link custom to="/sites/groups/new" v-slot="{href, navigate, isExactActive}">
                                <li :class="[isExactActive && 'active-page']">
                                    <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{$t('navigation.side.new_group')}}</a>
                                </li>
                            </router-link> -->
                    </ul>
                </li>
                <li v-if="$can('whitelist-client-view') && $can('whitelist-site-view') && selectedClient !== -1 && false">
                    <a href=""><i class="material-icons-two-tone">car_rental</i>{{ $t('navigation.side.whitelists') }}<i
                            class="material-icons has-sub-menu">keyboard_arrow_right</i> <i
                            class="material-icons has-sub-menu favourite" @click="addToFavourite('whitelist')"> favorite
                        </i> </a>
                    <ul class="sub-menu">
                        <router-link v-if="$can('whitelist-client-view') && selectedClient !== -1" custom
                            to="/whitelists/client" v-slot="{ href, navigate, isExactActive }">
                            <li :class="[isExactActive && 'active-page']">
                                <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{
                                    $t('navigation.side.client_whitelist') }}</a>
                            </li>
                        </router-link>
                        <router-link v-if="$can('whitelist-global')" custom to="/whitelists/global"
                            v-slot="{ href, navigate, isExactActive }">
                            <li :class="[isExactActive && 'active-page']">
                                <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{
                                    $t('navigation.side.global_whitelist') }}</a>
                            </li>
                        </router-link>
                    </ul>
                </li>
                <router-link v-if="$can('reports-view')" custom to="/reports" v-slot="{ href, navigate, isExactActive }">
                    <li :class="[isExactActive && 'active-page']">
                        <a :href="href" @click="navigate" :class="[isExactActive && 'active']"><i
                                class="material-icons-two-tone">summarize</i>{{ $t('navigation.side.reports') }} <i
                                class="material-icons has-sub-menu favourite" @click="addToFavourite('reports')"> favorite
                            </i> </a>
                    </li>
                </router-link>

                <!-- Video Guides -->
                <router-link v-if="$can('reports-view')" custom to="/guides" v-slot="{ href, navigate, isExactActive }">
                    <li :class="[isExactActive && 'active-page']">
                        <a :href="href" @click="navigate" :class="[isExactActive && 'active']"><i
                                class="material-icons-two-tone"> ondemand_video </i> {{ $t('navigation.side.video_guides')
                                }}
                            <i class="material-icons has-sub-menu favourite" @click="addToFavourite('video_guides')">
                                favorite </i> </a>
                    </li>
                </router-link>

                <!-- Changelog -->
                <!-- <router-link custom to="/changelog" v-slot="{ href, navigate, isExactActive }">
                    <li :class="[isExactActive && 'active-page']">
                        <a :href="href" @click="navigate" :class="[isExactActive && 'active']"><i
                                class="material-icons-two-tone">history</i> Changelog <i
                                class="material-icons has-sub-menu favourite" @click="addToFavourite('video_guides')">
                                favorite </i></a>
                    </li>
                </router-link> -->

                <router-link v-if="showStaging" custom to="/devices" v-slot="{ href, navigate, isExactActive }">
                    <li :class="[isExactActive && 'active-page']">
                        <a :href="href" @click="navigate" :class="[isExactActive && 'active']"><i
                                class="material-icons-two-tone">devices</i> Register Device <i
                                class="material-icons has-sub-menu favourite" @click="addToFavourite('video_guides')">
                                favorite </i></a>
                    </li>
                </router-link>

                <!-- Face Tab with Sub-menu -->
                <!-- v-if needs to be adjusted for permissions -->
                <li v-if="$can('faces.view')">
                    <a href="javascript:void(0);"><i class="material-icons-two-tone">face</i>{{ $t('navigation.side.face')
                    }}<i class="material-icons has-sub-menu">keyboard_arrow_right</i></a>
                    <ul class="sub-menu">
                        <!-- Faces Sub-tab -->
                        <router-link custom to="/face" v-slot="{ href, navigate, isExactActive }">
                            <li :class="[isExactActive && 'active-page']">
                                <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{
                                    $t('navigation.side.faces') }}</a>
                            </li>
                        </router-link>
                        <!-- Incidents Sub-tab -->
                        <router-link custom to="/incidents" v-slot="{ href, navigate, isExactActive }">
                            <li :class="[isExactActive && 'active-page']">
                                <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{
                                    $t('navigation.side.incidents') }}</a>
                            </li>
                        </router-link>
                    </ul>
                </li>

            </ul>

            <ul v-if="$can('clients-view') && selectedClient !== -1 && false" class="accordion-menu">
                <li class="sidebar-title">
                    {{ $t('navigation.side.sales') }}
                </li>

                <!-- Statistics -->
                <li>
                    <a href=""><i class="material-icons-two-tone"> leaderboard </i>{{ $t('navigation.side.statistics') }}<i
                            class="material-icons has-sub-menu">keyboard_arrow_right</i> <i
                            class="material-icons has-sub-menu favourite" @click="addToFavourite('reports')"> favorite </i>
                    </a>
                    <ul class="sub-menu">

                        <!-- Global Statistics -->
                        <router-link custom to="/statistics/global" v-slot="{ href, navigate, isExactActive }">
                            <li :class="[isExactActive && 'active-page']">
                                <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{
                                    $t('global.global',
                                        { text: 'Statistics' }) }}</a>
                            </li>
                        </router-link>

                        <!-- Client Statistics -->
                        <router-link custom to="/statistics/client" v-slot="{ href, navigate, isExactActive }">
                            <li :class="[isExactActive && 'active-page']">
                                <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{
                                    $t('global.client',
                                        { text: 'Statistics' }) }}</a>
                            </li>
                        </router-link>

                        <router-link custom to="/statistics/site" v-slot="{ href, navigate, isExactActive }">
                            <li :class="[isExactActive && 'active-page']">
                                <a :href="href" @click="navigate" :class="[isExactActive && 'active']">{{ $t('global.site',
                                    { text: 'Statistics' }) }}</a>
                            </li>
                        </router-link>
                    </ul>
                </li>

                <!-- Vehicle Search -->
                <router-link v-if="$can('vehicles-locate')" custom to="/vehiclesearch"
                    v-slot="{ href, navigate, isExactActive }">
                    <li :class="[isExactActive && 'active-page']">
                        <a :href="href" @click="navigate" :class="[isExactActive && 'active']"><i
                                class="material-icons-two-tone"> no_crash </i> {{ $t('navigation.side.vehicle_search') }} <i
                                class="material-icons has-sub-menu favourite" @click="addToFavourite('reports')"> favorite
                            </i> </a>
                    </li>
                </router-link>
            </ul>

        </div>
    </div>
</template>

<script>
import UserService from '../../services/user.service';
import Multiselect from 'vue-multiselect';
import axios from 'axios';
import authHeader from '../../services/auth-header';
import Battery from '@/components/Battery.vue';

export default {
    name: 'Sidebar',
    components: {
        Multiselect,
        Battery
    },
    mounted() {
        this.clients = this.$store.state.auth.user.clients;
        this.clients = this.clients.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        let selectedClients = this.clients.filter((client) => {
            return parseInt(client.id) == parseInt(this.selected_client);
        });
        this.selectedClient = selectedClients[0];

        this.checkBatteryStatus();

        axios.get(`https://api.varsanpr.com/api/staging/restricted/check`, {
            headers: authHeader()
        })
            .then(response => {
                if (response.data.success == true) {
                    this.showStaging = true;
                }
            })
            .catch(error => {

            });

        this.battery.interval = setInterval(() => {
            this.checkBatteryStatus();
        }, 60000);

    },
    beforeUnmount() {
        clearInterval(this.battery.interval);
    },
    data() {
        return {
            clients: [],
            favourites: [],
            selectedClient: null,
            night: this.$store.state.auth.status.dark,
            showStaging: false,
            battery: {
                show: false,
                level: 0,
                updated_at: null,
                interval: null
            }
        }
    },
    methods: {
        addToFavourite(id) {
            const item = document.getElementById(id);
            const list = document.getElementById('fav-list');
            console.log(list);
            list.appendChild(item);
        },
        selectClient(selectedClient) {
            UserService.selectClient(selectedClient || this.clients[0]).then(() => {
                this.$store.dispatch('auth/selectClient', selectedClient).then(() => {
                    this.$router.go(this.$router.currentRoute);
                })
            })
                .catch(error => {
                    // Handle error
                    // console.error(error);
                });

        },
        checkBatteryStatus(){
            axios.get(`https://api.varsanpr.com/api/account/battery`, {
                headers: authHeader()
            })
            .then(response => {
                if(response.data?.tablet){
                    this.battery.show = true;
                    this.battery.level = response.data.tablet.battery;
                    this.battery.updated_at = new Date(response.data.tablet.battery_updated_at).toLocaleDateString('en-GB') + ' ' + new Date(response.data.tablet.battery_updated_at).toLocaleTimeString('en-GB');
                }
            })
            .catch(error => {
                // Handle error
                console.error(error);
            });
        },
    },
    computed: {
        selected_client: function () {
            return this.$store.state.auth.user.selectedClient;
        }
    }
}
</script>

<style scoped>
.app-menu {
    height: calc(100vh - 181px) !important;
}

/* Sidebar title */
.logo-icon {
    width: 150% !important;
    height: 60px !important;
    background-size: 66% !important;
    border-radius: 0px !important;
    background-position: center left !important;
}

.hidden-sidebar-logo .logo-icon {
    background-size: 50% !important;
    height: 44.4px !important;
}

.no-fav {
    margin: 3px 15px;
    padding: 10px 15px;
    color: var(--font-color-secondary);
    text-align: center;
}

/* .no-fav-icon {
    margin-right: 13px;
    vertical-align: top;
    line-height: 20px;
} */

.add-to-favourite {
    padding: 0 10px;
    filter: none;
    color: #6d7b91;
    font-size: 19px;
}

.add-to-favourite:hover {
    cursor: pointer;
    color: var(--font-color-primary);
}

.favourite {
    display: none;
}

/* .app-menu>ul>li.active-page>a:hover .favourite{
    display: block;
}

.favourite:hover {
    color: #fff!important;
} */

.has-sub-menu:hover {
    display: none;
}

#privacy_link {
    text-decoration: none;
    color: white;
}

#privacy_link:hover {
    text-decoration: underline;
}
</style>